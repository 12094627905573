import Page from '../components/shared/page';
import Container from '../components/shared/container';
import IndexLayout from '../layouts';
import PageHero from '../components/shared/page-hero';

const TermsPage = () => (
  <IndexLayout title="Terms of Use">
    <Page>
      <PageHero>
        <h1>Terms of Use</h1>
      </PageHero>
      <Container className="short">
        <p>
          By accessing the website at https://storekick.io, you are agreeing
          to be bound by these terms of service, all applicable laws and
          regulations, and agree that you are responsible for compliance with
          any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The
          materials contained in this website are protected by applicable
          copyright and trademark law.
        </p>
        <strong>Use License</strong>
        <p>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on storekick.io's website for
          personal, non-commercial transitory viewing only. This is the grant
          of a license, not a transfer of title, and under this license you
          may not: modify or copy the materials; use the materials for any
          commercial purpose, or for any public display (commercial or
          non-commercial); attempt to decompile or reverse engineer any
          software contained on storekick.io's website; remove any copyright
          or other proprietary notations from the materials; or transfer the
          materials to another person or "mirror" the materials on any other
          server.
        </p>
        <p>
          This license shall automatically terminate if you violate any of
          these restrictions and may be terminated by storekick.io at any
          time. Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded
          materials in your possession whether in electronic or printed
          format.
        </p>
        <strong>Disclaimer</strong>
        <p>
          The materials on storekick.io's website are provided on an 'as is'
          basis. storekick.io makes no warranties, expressed or implied, and
          hereby disclaims and negates all other warranties including, without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of
          intellectual property or other violation of rights.
        </p>
        <p>
          Further, storekick.io does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use
          of the materials on its website or otherwise relating to such
          materials or on any sites linked to this site.
        </p>
        <strong>Limitations</strong>
        <p>
          In no event shall storekick.io or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on storekick.io's website, even if
          storekick.io or a storekick.io authorized representative has been
          notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <strong>Accuracy of materials</strong>
        <p>
          The materials appearing on storekick.io's website could include
          technical, typographical, or photographic errors. storekick.io does
          not warrant that any of the materials on its website are accurate,
          complete or current. storekick.io may make changes to the materials
          contained on its website at any time without notice. However
          storekick.io does not make any commitment to update the materials.
        </p>
        <strong>Links</strong>
        <p>
          storekick.io has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by storekick.io of
          the site. Use of any such linked website is at the user's own risk.
        </p>
        <strong>Modifications</strong>
        <p>
          storekick.io may revise these terms of service for its website at
          any time without notice. By using this website you are agreeing to
          be bound by the then current version of these terms of service.
        </p>
        <strong>Governing Law</strong>
        <p>
          These terms and conditions are governed by and construed in
          accordance with the laws of New York and you irrevocably submit to
          the exclusive jurisdiction of the courts in that State or location.
        </p>
      </Container>
    </Page>
  </IndexLayout>
);

export default TermsPage;
